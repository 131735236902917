import React from 'react';
import './Footer.scss';
import { Link } from "react-router-dom";
import DownloadButtons from './download_buttons/DownloadButtons.js';


function Footer() {
  return (
    <div className="footer">
      <div className="footer-links">
        <Link to="/contact">Contact us</Link>
        <Link to="/terms">Terms and Conditions</Link>
        <Link to="/privacy">Privacy Policy</Link>
      </div>
      <strong>&copy; 2023 CHRONOTRADE P.C. All rights reserved.</strong>
      <DownloadButtons/>
    </div>
  );
}

export default Footer;

