import './Home.scss';
import three from '../assets/three.png';
import phone from '../assets/bg2.png';
import phone2 from '../assets/bg3.png';
import transparency from '../assets/transparency.svg';
import integrity from '../assets/integrity.svg';
import mobility from '../assets/mobility.svg';
import efficiency from '../assets/efficiency.svg';
import DownloadButtons from '../components/download_buttons/DownloadButtons.js';
import Carousel from '../components/Carousel.js';
import c1 from '../assets/carousel/2.png';
import c2 from '../assets/carousel/3.png';
import c3 from '../assets/carousel/4.png';
import c4 from '../assets/carousel/5.png';
import c5 from '../assets/carousel/6.png';
import check from '../assets/check.svg';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';


function detectDeviceAndLink() {
  var ua = navigator.userAgent.toLowerCase();
  var androidUrl = "https://play.google.com/store/apps/details?id=com.jewelstrade";
  var appleStoreUrl = "https://apps.apple.com/app/id6474899733";  // Replace with your app's URL on the Apple App Store

  if (ua.indexOf("android") > -1) {
      return androidUrl;
  } else {
      return appleStoreUrl;
  }
}

function Home() {
  const location = useLocation();

  useEffect(() => {
      if (location.hash) {
          const element = document.getElementById(location.hash.substring(1));
          if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
          }
      }
  }, [location]);

  return (
    <div class="Home">
      <div className="banner">
        <div className="title">
            <div className="main-title">
                <h1>Seal Deals</h1>
                <h1>Swifter, Smarter and Safer</h1>
            </div>
            <div className="subtitle">
                <h2>#1 commission-free luxury jewelry trading platform</h2>
                <h2>Built for Jewel Traders.</h2>
            </div>
        </div>
      </div>

      <div className="offer">
        <img className="three" alt="3" src={three}/>
        <div>
             <h2>Months Free Trial</h2>
             <DownloadButtons/>
        </div>
      </div>

      <div className="time">
        <img alt="phone" src={phone}/>
        <div className="text">
            <h3>The global premier platform for buying, selling, and trading luxury jewelry.</h3>
            <h4>Revolutionize your business with</h4>
            <h4><strong>T.I.M.E.</strong></h4>
            <h4 className="initial"><strong>T</strong> - Transparency</h4>
            <h4 className="initial"><strong>I</strong> - Integrity</h4>
            <h4 className="initial"><strong>M</strong> - Mobility</h4>
            <h4 className="initial"><strong>E</strong> - Efficiency</h4>
        </div>
      </div>

      <div className="time-details">
        <div className="time-details-section">
            <img alt="transparency" src={transparency}/>
            <div className="text">
                <h3><strong>T</strong>ransparency</h3>
                <p>Commission-Free, No-hidden fees, Subscription-only Environment.</p>
                <p>Jewelstrade increases dealer profitability by allowing them to offer or obtain jewelry at lower prices.</p>
            </div>
        </div>
        <div className="time-details-section">
            <img alt="integrity" src={integrity}/>
            <div className="text">
                <h3><strong>I</strong>ntegrity</h3>
                <p>Jewelstrade fosters a trustworthy trading environment with end-to-end encryption, peer reviews, and dealer verification.</p>
                <p>Dealers can engage in high-value transactions with confidence.</p>
            </div>
        </div>
        <div className="time-details-section">
            <img alt="mobility" src={mobility}/>
            <div className="text">
                <h3><strong>M</strong>obility</h3>
                <p>The dedicated mobile app for both Android and iOS optimizes for the user experience.</p>
                <p>Dealers can quickly and engage with the platform anywhere.</p>
            </div>
        </div>
        <div className="time-details-section">
            <img alt="efficiency" src={efficiency}/>
            <div className="text">
                <h3><strong>E</strong>fficiency</h3>
                <p>Jewelstrade allows for quick and easy transactions.</p>
                <p>Dealers may spot wanted jewelry instantly, make prompt offers, or quickly list their inventory.</p>
            </div>
        </div>
      </div>

      <h3 id="howitworks" className="works">How it works</h3>
      <Carousel
        images={[c1,c2,c3,c4,c5]}
      />

      <div className="vip">
        <img alt="phone2" src={phone2}/>
        <div className="vip-club">
            <h3>Jewelstrade VIP Club</h3>
            <div className="benefit">
                <img alt="check" src={check}/>
                <p>
                    <span>Enhanced Profit Margins</span>
                    No commissions nor hidden fees
                </p>
            </div>
            <div className="benefit">
                <img alt="check" src={check}/>
                <p>
                    <span>Worldwide Inventory</span>
                    Unlock a global collection of jewelry
                </p>
            </div>
            <div className="benefit">
                <img alt="check" src={check}/>
                <p>
                    <span>Unlimited Uploads</span>
                    Showcase your entire collection
                </p>
            </div>
            <div className="benefit">
                <img alt="check" src={check}/>
                <p>
                    <span>Connect Securely</span>
                    Message fellow jewelstraders with end-to-end encryption
                </p>
            </div>
            <div className="benefit">
                <img alt="check" src={check}/>
                <p>
                    <span>Elite Network</span>
                    Meet and collaborate with a trusted community of reputable dealers
                </p>
            </div>
            <div className="benefit">
                <img alt="check" src={check}/>
                <p>
                    <span>Timely Market Demand</span>
                    Benefit from real-time market demand of the sold orders.
                </p>
            </div>
            <a href={detectDeviceAndLink()}>Get 3 Months Free Now</a>
            <p className="money">€99/month (after trial)</p>
        </div>
      </div>

    </div>
  );
}

export default Home;
