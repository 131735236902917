import './Contact.scss';

function Contact() {
  return (
    <div className="Contact">
      <div class="contact-title">Jewelstrade VIP Customer Care</div>
      <a href="mailto:sales@thejewelstrade.com" class="contact-link">sales@thejewelstrade.com</a>
    </div>
  );
}

export default Contact;
