import './Privacy.scss';

function Privacy() {
  return (
    <div className="Privacy">
      <h1><strong>Target of this Privacy Notice</strong></h1>

      <p>The current application “<strong>Jewelstrade</strong>“ (hereinafter “<strong>the application</strong>“) is owned by the company under the trade name “<strong>Jewelstrade P.C.</strong>“ (hereinafter “<strong>the Company</strong>“ or “<strong>We</strong>“). With respect to the privacy of the users of the application (hereinafter “<strong>the Users</strong>“ or “<strong>You</strong>“), collecting and processing your personal data with safety and keeping them secure is of utmost importance for our Company.</p>

      <p>The Company, as the Data Controller wishes through the current notice to inform you as Data Subjects, about the collection and processing of your personal data in the context of your using the application, according to the applicable European and national data protection legislation, especially the General Data Protection Regulation (ΕU) 2016/679, the Greek Laws 4624/2019 and 3471/2006 as applicable.</p>

      <h2><strong>Definitions</strong></h2>

      <ul>
        <li>“<strong>Personal data</strong>“ means any information relating to an identified or identifiable natural person (“data subject“); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person;</li>
        <li>“<strong>Personal data of special categories</strong>“ means any personal data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, and the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person's sex life or sexual orientation;</li>
        <li>“<strong>Processing</strong>“ means any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure, by transmission, dissemination or otherwise making available alignment or combination, restriction, erasure or destruction;</li>
        <li>“<strong>Anonymization</strong>“ means the processing of personal data in such a manner that the personal data can no longer be attributed to a specific data subject;</li>
        <li>“<strong>Pseudonymization</strong>“ means the processing of personal data in such a manner that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional information is kept separately and is subject to technical and organizational measures to ensure that the personal data are not attributed to an identified or identifiable natural person;</li>
        <li>“<strong>Data controller</strong>“ means the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data; where the purposes and means of such processing are determined by Union or Member State law, the controller or the specific criteria for its nomination may be provided for by Union or Member State law; For the purposes of the current notice, the Company acts as the Data Controller.</li>
        <li>“<strong>Data Processor</strong>“ means a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller;</li>
        <li>“<strong>Consent</strong>“ of the data subject means any freely given, specific, informed and unambiguous indication of the data subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her;</li>
        <li>“<strong>Personal data breach</strong>“ means a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, personal data transmitted, stored or otherwise processed;</li>
        <li>“<strong>Data Protection Legislation</strong>“ means any applicable legislation protecting the personal data of natural persons, including in particular the General Data Protection Regulation (EU) 2016/679 of the European Parliament and of the Council (“GDPR“), the Greek Laws 4624/2019 and 3471/2006, as well as any other applicable national legislation, including where applicable, statutes, decisions, guidelines, guidance notes, codes of practice, codes of conduct and data protection certification mechanisms issued from time to time by courts, any supervisory authority and other applicable authorities.</li>
      </ul>

      <h2><strong>Purpose of processing and legal basis</strong></h2>

      <p>In the context of browsing and using the application, the following personal data are collected and processed:</p>

      <table>
        <thead>
        <tr>
          <th>Personal Data</th>
          <th>Purpose of Processing</th>
          <th>Legal Basis</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Name, surname, email, phone number, country</td>
          <td>- Creating your account<br/>- Provision of services</td>
          <td>Processing is necessary for:<br/>- The performance of the contract<br/>- The establishment, exercise or defense ofour legal claims</td>
        </tr>
        <tr>
          <td>Photograph</td>
          <td>Creating your account</td>
          <td>Your prior explicit consent given by uploading your photograph at the application</td>
        </tr>
        <tr>
          <td>Any personal data may be included in the advertisement of the jewelry for sale</td>
          <td>Provision of services</td>
          <td>Your prior explicit consent given by uploading your advertisement of the jewelry you wish to sell</td>
        </tr>
        </tbody>
      </table>

      <h2><strong>Collection and further processing of Personal Data of Minors</strong></h2>

      <p>Generally, the Company does not collect and further process personal data of minors (i.e. people under the age of 18 years old). However, since it is not possible to verify the true age of the users of the application, it is highly advisable that the parents and legal guardians of minors contact the Company in case they notice any unauthorized sharing of personal data of minors under their responsibility, in order to exercise their personal data rights, such as the deletion of the minors' personal data. In case the Company realizes that has collected minor's personal data, it will promptly delete them.</p>

      <h2><strong>Recipients</strong></h2>

      <p>The personal information the Users provide us with is kept securely and is safeguarded. In the context and for the purposes of the operation and use of the application, the Company may transfer any from the above mentioned personal data to any third parties, to which may have assigned any personal data processing to be performed on its behalf (e.g. Companies offering services for the operation of the application).</p>

      <p>Any third parties to which personal data are transferred, bear contractual obligations before the Company (e.g. with data processing agreement, non-disclosure agreement, etc.) to comply with all their obligations arising from the Data Protection Legislation respecting the data subjects' rights.</p>

      <p>Furthermore, the personal data of the Users may be transferred to the police or/and any competent public authorities, etc. for the compliance of the Company with its legal obligations pursuant to the applicable legislation.</p>

      <h2><strong>International Data Transfers</strong></h2>

      <p>The Company does not transfer Users' personal to any third countries (i.e. countries outside the European Economic Area (EEA)]. In case that an international data transfer shall take place for one of the above mentioned purposes, the Company prior to the said transfer, assures that one of the legal basis previewed by the Article 6 of the GDPR exists and that:</p>

      <ul>
        <li>The Commission has decided that the third country ensures an adequate level of protection. (Article 45 GDPR), or</li>
        <li>Appropriate safeguards are provided for the security of the personal data transferred and on condition that enforceable data subject rights and effective legal remedies for data subjects are available. (Article 46 GDPR), or</li>
        <li>For non-repetitive processing activities, the transfer shall take place only on one of the conditions of Article 49 GDPR.</li>
      </ul>

      <p>Otherwise, the international transfer is prohibited thus the Company will not perform it unless one of the special conditions under GDPR exists (e.g. User's explicit prior consent after their proper information on the risks arising from the transfer, the transfer is necessary for the performance of the contract upon user's relevant request, the task shall be carried out in the public interest or for the establishment, exercise or defense of legal claims or user's vital interests, etc).</p>

      <h2><strong>Data Retention Period</strong></h2>

      <p>Users' personal data are collected and further processed for a determined and specific period of time, which is strictly necessary for the performance of the purpose of processing. Upon the expiration of this period, the personal data are properly deleted from our databases, indicatively chat information will be deleted within 14 days, unless the users choose to delete them soonercontinued...</p>

      <p>as soon as 1 day.</p>

      <p>When the processing is performed under a special legal obligation, users' personal data are kept for as long as it is previewed by the relevant legal provision.</p>

      <p>Users' personal data collected and further processed for the performance of a contract, are kept for as long as it is necessary for the said performance as well as for the establishment, exercise, or defense of legal claims that may arise from the contract.</p>

      <p>In regard to the personal data collected and processed under the data subject's specific consent, they are kept until the data subject withdraws the relevant consent unless the Company is obliged to retain them for the performance of its legal obligations.</p>

      <h2><strong>Personal Data Breach</strong></h2>

      <p>In case that a personal data breach takes place, the Company has adopted and follows a specific Personal Data Breach Handling Procedure. In case you notice anything that may be or cause a personal data breach, please inform us as soon as possible accordingly at the email address sales@thejewelstrade.com or Leof. Siggrou 196, Kallithea, 17671, Greece.</p>

      <h2><strong>Personal Data Security</strong></h2>

      <p>Taking into account the state of the art, the cost of implementation and the nature, scope, context, and purposes of processing as well as the risks of varying likelihood and severity for rights and freedoms of natural persons posed by the processing performed, the Company has implemented and keeps updated all the necessary technical and organizational measures for the security of the personal data processed and the data subjects' rights. Although, no method of personal data processing via the Internet or electronic storage can be absolutely safe, the Company has in place several digital security measures (e.g. end-to-end encryption) pursuant to the applicable Data Protection Legislation.</p>

      <h2><strong>Personal Data Rights</strong></h2>

      <p>The Company commits itself to users' privacy. We take care so that handle properly with all data subjects' requests concerning the exercise of their rights under the Data Protection Legislation.</p>

      <p>Especially, every data subject has the following rights:</p>

      <ul>
        <li>Right to Information and Access to the personal data processed by the Company. Every data subject has the right to ask to be informed and receive a copy of the personal data processed by the Company so that they be able to check the lawfulness of their processing.</li>
        <li>Right to Rectification of the personal data processed by the Company. Every data subject has the right to ask for the rectification of any inaccurate personal data concerning them. Taking into account the purposes of the processing, the data subject has the right to have incomplete personal data completed, including by means of providing a supplementary statement.</li>
        <li>Right to Erasure of the personal data processed by the Company. Every data subject has the right to ask for the erasure of their personal data since they are no longer necessary in relation to the purposes for which they were collected or otherwise processed and there is no legal provision requiring their further retention.</li>
        <li>Right to Restriction of processing of the personal data processed by the Company.</li>
        <li>Right to Data Portability of the personal data processed by the Company. Every data subject has the right to receive the personal data concerningthem, which they have provided to the Company, in a structured, commonly used, and machine-readable format.</li>
        <li>Right to Objection to the processing of their personal data by the Company if such processing is performed for the purposes of the legitimate interests pursued by the Company.</li>
        <li>Right to Withdraw your consent at any time, bearing in mind that such withdrawal does not affect the lawfulness of the processing before the withdrawal.</li>
      </ul>

      <p>In case that any data subject exercises any of the above mentioned rights, the Company shall without undue delay handle with it [at any case within thirty (30) calendar days from the receipt of the request and the proper identification of the data subject] informing the data subject in written for the progress of the request. That period may be extended by two further months where necessary, taking into account the complexity and number of the requests. The Company shall inform the data subject of any such extension within one month of receipt of the request, together with the reasons for the delay.</p>

      <p>In case any user thinks that we have not responded to their request properly, they can always refer to the Hellenic Data Protection Authority at www.dpa.gr.</p>

      <h2><strong>Contact Personal for Privacy Matters</strong></h2>

      <p>For the exercise of all the above mentioned rights as well as for any questions regarding personal data processing by the Company, you can always contact us at the email address sales@thejewelstrade.com</p>

      <h2><strong>Update</strong></h2>

      <p>The current Privacy Notice may be updated from time to time for the Company's compliance with the applicable Data Protection Legislation as well as for the amelioration and update of the services offered through the application. Therefore, we suggest that before using the application, you refer to the current Privacy Notice so that you read its latest version.</p>

      <p>Last Update: March 2023</p>
    </div>
  );
}

export default Privacy;
