import React, { useState } from 'react';
import './Header.scss';
import { Link } from "react-router-dom";
import DownloadButtons from './download_buttons/DownloadButtons.js';

function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <div className="header">
      <div className="left-section">
        <img src="logo.png" alt="Logo" className="logo" />
        <h1 className="title">
            <Link to="/">Jewelstrade</Link>
        </h1>
      </div>
      <div className={isMenuOpen ? 'center-section open' : 'center-section'}>
        <Link to="/">Home</Link>
        <a href="/#howitworks">How it works</a>
        <Link to="/contact">Contact us</Link>
        <DownloadButtons/>
      </div>
      <div className="right-section">
        <button className="menu-button" onClick={() => setMenuOpen(!isMenuOpen)}>☰</button>
        <DownloadButtons/>
      </div>
    </div>
  );
}

export default Header;
